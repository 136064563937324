import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import LogoSVG from '../assets/webitwork.com.svg';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('home');
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  // Funktion zum Umschalten des Menüs
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Funktion zum Schließen des Menüs
  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  // Funktion zum Ermitteln des aktiven Abschnitts basierend auf der Scroll-Position
  const determineActiveSection = () => {
    const sections = ['home', 'services', 'pricing', 'about', 'projects', 'faq', 'contact'];
    
    for (const section of sections) {
      const element = document.getElementById(section);
      if (element) {
        const rect = element.getBoundingClientRect();
        // Wenn der Abschnitt im Viewport ist (mit etwas Toleranz)
        if (rect.top <= 100 && rect.bottom >= 100) {
          setActiveSection(section);
          break;
        }
      }
    }
  };

  // Scroll-Event-Listener hinzufügen
  useEffect(() => {
    const handleScroll = () => {
      // Bestimme, ob gescrollt wurde (für Schatten-Effekt)
      setScrolled(window.scrollY > 10);
      
      // Bestimme den aktiven Abschnitt
      determineActiveSection();
    };

    window.addEventListener('scroll', handleScroll);
    
    // Initialen aktiven Abschnitt bestimmen
    determineActiveSection();
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Funktion zum Bestimmen der Klasse für einen Menüpunkt
  const getLinkClass = (section: string) => {
    return `text-gray-700 hover:text-primary font-medium transition-colors duration-200 ${
      activeSection === section ? 'text-primary border-b-2 border-primary' : ''
    }`;
  };

  return (
    <header className={`bg-white sticky top-0 z-50 transition-shadow duration-300 ${
      scrolled ? 'shadow-md' : ''
    }`}>
      <div className="container py-4 flex justify-between items-center">
        <div className="flex items-center">
          <Link to="/">
            <img 
              src={LogoSVG} 
              alt="WebItWork.com Logo" 
              className="h-10 md:h-12 lg:h-16 w-40 lg:w-64" 
              width="256"
              height="64"
              style={{ 
                imageRendering: 'crisp-edges',
                objectFit: 'contain',
                aspectRatio: '4/1'
              }}
              loading="eager"
            />
          </Link>
        </div>

        {/* Desktop Navigation */}
        <nav className="hidden md:flex space-x-8">
          {isHomePage ? (
            <>
              <a href="#home" className={getLinkClass('home')}>
                Home
              </a>
              <a href="#services" className={getLinkClass('services')}>
                Dienstleistungen
              </a>
              <a href="#pricing" className={getLinkClass('pricing')}>
                Preise
              </a>
              <a href="#about" className={getLinkClass('about')}>
                Über uns
              </a>
              <a href="#projects" className={getLinkClass('projects')}>
                Projekte
              </a>
              <a href="#faq" className={getLinkClass('faq')}>
                FAQ
              </a>
              <a href="#contact" className={getLinkClass('contact')}>
                Kontakt
              </a>
            </>
          ) : (
            <>
              <Link to="/" className="text-gray-700 hover:text-primary font-medium transition-colors duration-200">
                Home
              </Link>
              <Link 
                to="/" 
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = '/#services';
                }}
                className="text-gray-700 hover:text-primary font-medium transition-colors duration-200"
              >
                Dienstleistungen
              </Link>
              <Link 
                to="/" 
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = '/#pricing';
                }}
                className="text-gray-700 hover:text-primary font-medium transition-colors duration-200"
              >
                Preise
              </Link>
              <Link 
                to="/" 
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = '/#about';
                }}
                className="text-gray-700 hover:text-primary font-medium transition-colors duration-200"
              >
                Über uns
              </Link>
              <Link 
                to="/" 
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = '/#projects';
                }}
                className="text-gray-700 hover:text-primary font-medium transition-colors duration-200"
              >
                Projekte
              </Link>
              <Link 
                to="/" 
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = '/#faq';
                }}
                className="text-gray-700 hover:text-primary font-medium transition-colors duration-200"
              >
                FAQ
              </Link>
              <Link 
                to="/" 
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = '/#contact';
                }}
                className="text-gray-700 hover:text-primary font-medium transition-colors duration-200"
              >
                Kontakt
              </Link>
            </>
          )}
        </nav>

        {/* Mobile Menu Button */}
        <button
          className="md:hidden text-gray-700 focus:outline-none relative w-8 h-8"
          onClick={toggleMenu}
          aria-label="Toggle menu"
          aria-expanded={isMenuOpen}
        >
          <div className="absolute inset-0 flex items-center justify-center">
            <span 
              className={`block w-6 h-0.5 bg-gray-700 transition-transform duration-300 ease-in-out ${
                isMenuOpen ? 'rotate-45 translate-y-0' : '-translate-y-1.5'
              }`}
            ></span>
            <span 
              className={`block w-6 h-0.5 bg-gray-700 transition-opacity duration-300 ease-in-out ${
                isMenuOpen ? 'opacity-0' : 'opacity-100'
              }`}
            ></span>
            <span 
              className={`block w-6 h-0.5 bg-gray-700 transition-transform duration-300 ease-in-out ${
                isMenuOpen ? '-rotate-45 translate-y-0' : 'translate-y-1.5'
              }`}
            ></span>
          </div>
        </button>

        {/* Mobile Navigation */}
        <div 
          className={`md:hidden absolute top-16 left-0 right-0 bg-white shadow-md overflow-hidden transition-all duration-300 ease-in-out ${
            isMenuOpen 
              ? 'max-h-[500px] opacity-100 transform translate-y-0' 
              : 'max-h-0 opacity-0 transform -translate-y-2'
          }`}
        >
          <div className="flex flex-col p-4">
            {isHomePage ? (
              <>
                <a
                  href="#home"
                  className={`py-2 px-4 rounded-md ${getLinkClass('home')} ${
                    activeSection === 'home' ? 'bg-gray-100' : ''
                  }`}
                  onClick={closeMenu}
                >
                  Home
                </a>
                <a
                  href="#services"
                  className={`py-2 px-4 rounded-md ${getLinkClass('services')} ${
                    activeSection === 'services' ? 'bg-gray-100' : ''
                  }`}
                  onClick={closeMenu}
                >
                  Dienstleistungen
                </a>
                <a
                  href="#pricing"
                  className={`py-2 px-4 rounded-md ${getLinkClass('pricing')} ${
                    activeSection === 'pricing' ? 'bg-gray-100' : ''
                  }`}
                  onClick={closeMenu}
                >
                  Preise
                </a>
                <a
                  href="#about"
                  className={`py-2 px-4 rounded-md ${getLinkClass('about')} ${
                    activeSection === 'about' ? 'bg-gray-100' : ''
                  }`}
                  onClick={closeMenu}
                >
                  Über uns
                </a>
                <a
                  href="#projects"
                  className={`py-2 px-4 rounded-md ${getLinkClass('projects')} ${
                    activeSection === 'projects' ? 'bg-gray-100' : ''
                  }`}
                  onClick={closeMenu}
                >
                  Projekte
                </a>
                <a
                  href="#faq"
                  className={`py-2 px-4 rounded-md ${getLinkClass('faq')} ${
                    activeSection === 'faq' ? 'bg-gray-100' : ''
                  }`}
                  onClick={closeMenu}
                >
                  FAQ
                </a>
                <a
                  href="#contact"
                  className={`py-2 px-4 rounded-md ${getLinkClass('contact')} ${
                    activeSection === 'contact' ? 'bg-gray-100' : ''
                  }`}
                  onClick={closeMenu}
                >
                  Kontakt
                </a>
              </>
            ) : (
              <>
              <Link
                to="/"
                className="py-2 px-4 rounded-md text-gray-700 hover:text-primary font-medium transition-colors duration-200"
                onClick={closeMenu}
              >
                Home
              </Link>
              <Link
                to="/"
                className="py-2 px-4 rounded-md text-gray-700 hover:text-primary font-medium transition-colors duration-200"
                onClick={(e) => {
                  closeMenu();
                  e.preventDefault();
                  window.location.href = '/#services';
                }}
              >
                Dienstleistungen
              </Link>
              <Link
                to="/"
                className="py-2 px-4 rounded-md text-gray-700 hover:text-primary font-medium transition-colors duration-200"
                onClick={(e) => {
                  closeMenu();
                  e.preventDefault();
                  window.location.href = '/#pricing';
                }}
              >
                Preise
              </Link>
              <Link
                to="/"
                className="py-2 px-4 rounded-md text-gray-700 hover:text-primary font-medium transition-colors duration-200"
                onClick={(e) => {
                  closeMenu();
                  e.preventDefault();
                  window.location.href = '/#about';
                }}
              >
                Über uns
              </Link>
                <Link
                  to="/"
                  className="py-2 px-4 rounded-md text-gray-700 hover:text-primary font-medium transition-colors duration-200"
                  onClick={(e) => {
                    closeMenu();
                    e.preventDefault();
                    window.location.href = '/#projects';
                  }}
                >
                  Projekte
                </Link>
                <Link
                  to="/"
                  className="py-2 px-4 rounded-md text-gray-700 hover:text-primary font-medium transition-colors duration-200"
                  onClick={(e) => {
                    closeMenu();
                    e.preventDefault();
                    window.location.href = '/#faq';
                  }}
                >
                  FAQ
                </Link>
                <Link
                  to="/"
                  className="py-2 px-4 rounded-md text-gray-700 hover:text-primary font-medium transition-colors duration-200"
                  onClick={(e) => {
                    closeMenu();
                    e.preventDefault();
                    window.location.href = '/#contact';
                  }}
                >
                  Kontakt
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
